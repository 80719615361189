var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"grading-assessment py-2"}},[_c('section',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('SearchBar',{staticClass:"mb-5",attrs:{"placeholder":'Search student name',"value":_vm.search},on:{"update:value":function($event){_vm.search=$event},"clear":_vm.clearSearch,"search":_vm.onSearch}}),_c('section',{staticClass:"d-flex flex-row ml-auto"},[_c('v-btn',{staticClass:"poppins f12 fw500 text-capitalize mx-1 mb-5",attrs:{"outlined":"","color":"primary","dense":"","loading":_vm.loading1},on:{"click":function($event){return _vm.mark(true)}}},[_vm._v(" Mark as Completed ")]),_c('v-btn',{staticClass:"poppins f12 fw500 text-capitalize mx-1 mb-5",attrs:{"outlined":"","color":"primary","dense":"","loading":_vm.loading2},on:{"click":function($event){return _vm.mark(false)}}},[_vm._v(" Mark as Pending ")])],1)],1),_c('v-data-table',{staticClass:"text--center poppins custom-border",attrs:{"headers":_vm.grading_assessment_student_tbl,"items":_vm.gradingAssessment,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
    },"page":_vm.page,"server-items-length":_vm.totalCount},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"pagination":(e) => {
        _vm.page = e.page
        _vm.paginate = String(e.itemsPerPage),
        _vm.updateQuery()
    }},scopedSlots:_vm._u([{key:"header.select",fn:function({ item }){return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":""},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]}},{key:"item.select",fn:function({ item }){return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"value":item.id,"hide-details":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}},{key:"item.checked",fn:function({ item }){return [_c('span',{class:item.checked == 'Completed' ? 'green--text' : 'grey--text'},[_vm._v(_vm._s(item.checked))])]}},{key:"item.date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$dateFormat.mmDDyy(item.created_at))+" ")]}},{key:"item.time",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$dateFormat.hhMM(item.created_at))+" ")]}},{key:"item.action",fn:function({ item }){return [_c('v-btn',{staticClass:"primary--text text-capitalize roboto f14",attrs:{"text":"","exact":"","to":{
          name: `${_vm.user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading Assessment User`,
          params: { course_id: _vm.$route.params.course_id, id: item.assessment_id, user_assessment_id: item.id, user_id: item.user.id },
        },"icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }